<script>
import Gen from '@helper/Gen.js';
import env from '../../env.js';

export default {
	props: ['name', 'value', 'config','isRequired'],
	computed:{
		page(){ return this.$root.page },
	},
	async mounted(){
		global.CKEDITOR_BASEPATH = env.baseUrl+'/plugins/ckeditor/'
		await Gen.loadScript("/plugins/ckeditor/ckeditor.js")
		
		this.init()
	},
	methods:{
		init(){
			this.ck = global.CKEDITOR.replace(this.name, Object.assign({
				height: '200px',
				// toolbar: [
				// 	{ name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
				// 	{ name: 'document', items: ['Save', 'NewPage', 'Preview', 'Print', '-', 'Templates'] },
				// 	{ name: 'forms', items: ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField'] },
				// 	{ name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat'] },
				// 	{ name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language'] },
				// 	'/',
				// 	{ name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord','pastecode', '-', 'Undo', 'Redo'] },
				// 	{ name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
				// 	{ name: 'insert', items: ['Image', 'embedsemantic', 'Table', 'HorizontalRule', 'SpecialChar', 'Iframe'] },
				// 	{ name: 'colors', items: ['TextColor', 'BGColor'] },
				// 	{ name: 'tools', items: ['Maximize', 'ShowBlocks', 'Source'] },
				// 	// { name: 'others', items: ['-'] }
				// ],
				autoGrow_maxHeight: 420,
				extraPlugins: ['autogrow','pastecode'/* ,'imagebrowser' */,'wordcount','youtube','colorbutton','panelbutton'],
				allowedContent: true,
				embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
				// embed_provider: '//noembed.com/embed?url={url}&callback={callback}',
				filebrowserUploadUrl: env.baseUrl+'/ajax/upload?pageType=editor',
				resize_enabled : false,
			}, this.config || {}))
			this.ck.on('change', () => {
				this.$emit('input', this.ck.getData())
				this.onEdit = true
				this.$el.value = this.ck.getData()
				Gen.delay(() => {
					this.onEdit = false
					$(this.$el).valid()
				}, 300)
			})
			this.ck.on("instanceReady",()=>{ setTimeout(()=>{
				if(this.value){
					this.$el.value = this.value
					this.ck.setData(this.value)
				}
			}, 300) })
		}
	},
	watch: {
		value: function (v) {
			if(!v) this.ck.setData("")
			if (!this.onEdit){
				if(this.ck) this.ck.setData(v)
			}
		}
	},
	destroyed: function () {
    $("#cke_"+this.name).remove();
  }
}
</script>

<template>
  <textarea :name="name" :id="name"></textarea>
</template>